function Contact() {
    return (
        <article id="contact" className="wrapper style4">
				<div className="container medium">
					<footer>
						<ul id="copyright">
							<li>Website Maintained by <a href="https://github.com/cireneirbo">Eric O'Brien</a></li>
						</ul>
					</footer>
				</div>
			</article>
    );
}

export default Contact;